<template>
  <van-tab title="新增留言" name="create">
    <van-form input-align="right" scroll-to-error @submit="onSubmit" :disabled="isLoading" :show-error="false">
      <div class="field-group">
        <van-field
          v-model="form.insureArea"
          name="insureArea"
          label="参保地"
          placeholder="请选择参保地"
          right-icon="arrow"
          :rules="rules.insureAreaCode"
          readonly
          clickable
          @click="showPickerWidth('instArea')"
        ></van-field>
        <van-field
          :value="selectedCategory"
          name="secondCategory"
          label="咨询问题类型"
          placeholder="请选择咨询问题类型"
          right-icon="arrow"
          :rules="rules.secondCategory"
          readonly
          clickable
          @click="showPickerWidth('category')"
        ></van-field>
        <van-field
          v-model="form.userName"
          name="userName"
          label="姓名"
          placeholder="请输入姓名"
          :rules="rules.userName"
          maxlength="20"
        ></van-field>
        <van-field
          v-model="form.phoneNumber"
          name="phoneNumber"
          label="手机号码"
          placeholder="请输入手机号码"
          :rules="rules.phoneNumber"
          type="number"
          maxlength="11"
        ></van-field>
      </div>

      <div class="field-group" style="padding-bottom: 10px;">
        <label for class="custom-label">留言内容</label>
        <van-field
          v-model="form.comments"
          placeholder="点击输入留言内容..."
          rows="1"
          autosize
          type="textarea"
          class="text-left"
          name="comments"
          :rules="rules.comments"
          maxlength="100"
          show-word-limit
        ></van-field>
      </div>

      <div class="field-group" style="padding-bottom: 20px;">
        <label for class="custom-label">
          上传图片
          <span class="field-help">最多上传6张图片，单张图片体积限制为5M</span>
        </label>
        <van-field>
          <template #input>
            <van-uploader
              v-model="fileList"
              :upload-icon="uploadIcon"
              :max-count="6"
              :before-read="validFile"
              :after-read="getFiles"
              @delete="removeFiles"
            />
          </template>
        </van-field>
        <van-button
          block
          size="small"
          color="#00ae7e"
          native-type="submit"
          :loading="isLoading"
          loading-text="正在提交..."
        >提交留言</van-button>
      </div>
    </van-form>

    <!-- 参保地选择的弹框部分 -->
    <van-popup v-model="isPickerShown" position="bottom">
      <van-picker
        show-toolbar
        :title="pickerTitle"
        :columns="pickerData"
        @cancel="isPickerShown = false"
        @confirm="onConfirm"
        :loading="pickerLoading"
        value-key="name"
      />
    </van-popup>
  </van-tab>
</template>

<script>
import { Tab, Form, Field, Button, Uploader, Popup, Picker, Notify } from 'vant'
import { submitComment, getInstAreas, getCategory } from '@/api/comments.js'
import { mapGetters } from 'vuex'

// 验证值去空格
const trimValue = value => {
  if (typeof value === 'string') {
    return value.trim()
  }
  return value
}

export default {
  name: 'Create',
  data () {
    return {
      form: {
        userId: null,
        userName: null,
        firstCategory: null,
        firstCategoryName: null,
        secondCategory: null,
        secondCategoryName: null,
        insureArea: null,
        insureAreaCode: null,
        source: '4', // 来源 1公众号 2小程序 3APP 4H5 5PC 6电话
        phoneNumber: null,
        comments: null,
        files: []
      },
      fileList: [],
      // 数据输入项的验证规则
      rules: {
        insureAreaCode: [{ required: true, message: '请选择参保地', formatter: trimValue }],
        secondCategory: [{ required: true, message: '请选择问题分类', formatter: trimValue }],
        userName: [{ required: true, message: '请输入姓名', formatter: trimValue }],
        phoneNumber: [{ required: true, message: '请输入手机号码', formatter: trimValue }, {
          message: '请输入正确的手机号码',
          formatter: trimValue,
          pattern: /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/
        }],
        comments: [{ required: true, message: '请输入留言内容', formatter: trimValue }]
      },
      uploadIcon: require('@/assets/icons/upload-image.png'),
      isLoading: false,
      isPickerShown: false,
      pickerMode: 'instArea',
      pickerLoading: false,
      instAreaList: [], // 参保地的数据
      knowledgeCategory: [] // 问题的分类数据
    }
  },
  computed: {
    ...mapGetters(['userData']),
    pickerData () {
      if (this.pickerMode === 'instArea') {
        if (this.instAreaList.length < 1) {
          // 获取参保地数据
          this.getInstAreas()
        }
        return this.instAreaList
      } else {
        if (this.knowledgeCategory.length < 1) {
          // 获取知识分类数据
          this.getCategory()
        }
        return this.knowledgeCategory
      }
    },
    pickerTitle () {
      if (this.pickerMode === 'instArea') {
        return this.form.insureArea || '请选择'
      } else {
        return this.selectedCategory || '请选择'
      }
    },
    selectedCategory () {
      const { firstCategoryName, secondCategoryName } = this.form
      if (secondCategoryName) {
        return firstCategoryName + '/' + secondCategoryName
      } else {
        return firstCategoryName
      }
    }
  },
  watch: {
    // userData: {
    //   immediate: true,
    //   handler (value) {
    //     if (value) {
    //       this.form.userId = this.userData.userId
    //       this.form.userName = this.userData.userName
    //       this.form.insureArea = this.userData.insureArea
    //       this.form.insureAreaCode = this.userData.insureAreaCode
    //       this.form.phoneNumber = this.userData.phoneNumber
    //     }
    //   }
    // }
  },
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
    [Tab.name]: Tab,
    [Uploader.name]: Uploader,
    [Popup.name]: Popup,
    [Picker.name]: Picker
  },
  created () {
    this.resetForm()
  },
  methods: {
    /**
     * 控制picker 面板的展示
     */
    showPickerWidth (mode) {
      this.isPickerShown = true
      this.pickerMode = mode
    },
    /**
     * 获取参保地数据的方法
     */
    getInstAreas () {
      this.pickerLoading = true
      // 对数据进行二次加工
      const optimizeData = data => {
        if (data.children && data.children.length) {
          data.children.map(item => {
            return optimizeData(item)
          })
        } else {
          delete data.children
        }
        return data
      }
      getInstAreas()
        .then(res => {
          this.instAreaList = (res.data || []).map(optimizeData)
        })
        .finally(() => {
          this.pickerLoading = false
        })
    },
    /**
     * 获取问题分类
     */
    getCategory () {
      this.pickerLoading = true
      // 对数据进行二次加工
      const optimizeData = data => {
        // 去掉二级分类，只保留一级，产品的意思是不需要统计这么细致
        delete data.children
        // if (data.categoryList && data.categoryList.length) {
        //   data.children = data.categoryList
        //   delete data.categoryList
        //   data.children.map(item => {
        //     return optimizeData(item)
        //   })
        // } else {
        //   delete data.children
        // }
        data.name = data.title
        return data
      }
      getCategory()
        .then(res => {
          this.knowledgeCategory = (res.data || []).map(optimizeData)
        })
        .finally(() => {
          this.pickerLoading = false
        })
    },
    /**
     * picker 选中结果的回调
     */
    onConfirm (names, path) {
      let name
      if (path instanceof Array) {
        name = names.length === 2 ? names[1] : names[0]
      } else {
        name = names
      }
      // 根据 index 索引找到目标的数据
      const findTargetByPath = (data, path, all) => {
        const start = path.shift()
        const targets = []
        let target = data[start]
        targets.push(target)
        while (path.length) {
          target = target.children[path.shift()]
          targets.push(target)
        }
        return all ? targets : target
      }
      // console.log(target)
      // console.log(arguments)
      if (this.pickerMode === 'instArea') {
        const target = findTargetByPath(this.instAreaList, path)
        this.form.insureArea = name
        this.form.insureAreaCode = target.code
      } else {
        // 如果只是分类保留2级就用这里
        // const targets = findTargetByPath(this.knowledgeCategory, path, true)
        // const [firstCategory, secondCategory] = targets
        // this.form.firstCategory = firstCategory ? firstCategory.id : null
        // this.form.firstCategoryName = firstCategory ? firstCategory.name : null
        // this.form.secondCategory = secondCategory ? secondCategory.id : null
        // this.form.secondCategoryName = secondCategory
        //   ? secondCategory.name
        //   : null

        this.form.firstCategory = name ? name.id : null
        this.form.firstCategoryName = name ? name.name : null
        this.form.secondCategory = null
        this.form.secondCategoryName = null
      }

      this.isPickerShown = false
    },
    /**
     * 文件上传之前检验格式
     */
    validFile (file) {
      const { name, size } = file
      // 体积验证
      console.log('当前选择的图片体积是： ' + size)
      if (size > 5 * 1024 * 1024) { // (1M = 1024 * 1024 byte)
        Notify({
          type: 'danger',
          message: '图片体积过大'
        })
        return false
      }
      if (!/.(png|jpg|jpeg)$/.test(name.toLowerCase())) {
        Notify({
          type: 'danger',
          message: '请上传格式为 png、jpg、jpeg 的图片'
        })
        return false
      }
      return true
    },
    /**
     * 文件上传后的存储操作
     */
    getFiles (file) {
      this.form.files.push(file.file)
    },
    /**
     * 删除已上传的文件
     */
    removeFiles (file, detail) {
      // 删除数据
      this.form.files.splice(detail.index, 1)
    },
    /**
     * 提交数据操作
     */
    onSubmit () {
      const _self = this
      this.isLoading = true

      this.form.userId = this.userData.userId
      this.form.userName = this.userData.userName
      this.form.insureArea = this.userData.insureArea
      this.form.insureAreaCode = this.userData.insureAreaCode
      this.form.phoneNumber = this.userData.phoneNumber

      submitComment(this.form)
        .then(res => {
          Notify({
            type: 'success',
            message: '留言提交成功'
          })
          _self.$router.replace({ name: 'VisitorBook' })
          // 重置数据
          this.resetForm()
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    /**
     * 重置表单
     */
    resetForm () {
      this.form.userId = this.userData.id
      this.form.userName = this.userData.userName
      this.form.firstCategory = null
      this.form.firstCategoryName = null
      this.form.secondCategory = null
      this.form.secondCategoryName = null
      this.form.secondCategoryName = null
      this.form.insureArea = this.userData.insureArea
      this.form.insureAreaCode = this.userData.insureAreaCode
      this.form.source = window.config.source
      this.form.phoneNumber = this.userData.phoneNumber
      this.form.comments = null
      this.form.files = []
      this.fileList = []
    }
  }
}
</script>

<style lang="scss" scoped>
.field-group {
  background-color: #ededed;
  margin-bottom: 10px;
  .custom-label {
    display: flex;
    font-size: 26px;
    color: #303133;
    padding: 25px 28px 5px;
    line-height: 40px;
    align-items: center;
    .field-help {
      font-size: 20px;
      color: #909399;
      margin-left: 20px;
    }
  }
}
::v-deep {
  .van-cell {
    background: transparent;
    .van-field__label {
      font-size: 26px;
      color: #303133;
    }
    .van-field__control {
      font-size: 26px;
      color: #909399;
    }
    .van-field__control--custom {
      text-align: left;
      justify-content: flex-start;
    }
    &.text-left .van-field__body {
      border: 1px solid #d8d8d8;
      border-radius: 8px;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 8px;
      padding-bottom: 8px;
      .van-field__control {
        text-align: left;
      }
    }
    .van-field__right-icon {
      margin-right: -30px;
      padding-left: 8px;
    }
  }
  .van-field__error-message {
    // position: absolute;
    // right: 0;
    // bottom: 0;
    font-size: 22px;
    text-align: right;
  }
  // 上传组件
  .van-uploader {
    width: calc(100% + 15px);
    margin-left: -15px;
  }
  .van-uploader__wrapper {
    width: 100%;
    .van-uploader__input {
      z-index: 10;
    }
  }
  .van-uploader__preview,
  .van-uploader__upload {
    box-sizing: border-box;
    flex: 1;
    min-width: 33.333%;
    max-width: 33.333%;
    width: 33.333%;
    height: 150px;
    margin: 0;
    padding-left: 15px;
    padding-bottom: 15px;
  }
  .van-uploader__preview-delete {
    background-color: rgba(0, 0, 0, 0.5);
    width: auto;
    height: auto;
    right: 0;
    left: 15px;
    bottom: 15px;
    border-radius: 10px;
    .van-icon-cross {
      color: #fff;
      border: 1px solid #fff;
      border-radius: 50px;
      width: 50px;
      height: 50px;
      text-align: center;
      line-height: 50px;
    }
  }
  .van-uploader__upload {
    background: transparent;
    position: relative;
    z-index: 1;
    .van-icon {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background-color: #ffffff;
      text-align: center;
      .van-icon__image {
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .van-uploader__preview-image {
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    height: 100%;
  }

  .van-button .van-loading {
    position: relative;
    top: -4px;
  }
}
</style>
