<template>
  <van-tab title="正在进行" name="inProcess" key>
    <van-loading v-show="loading" color="rgb(0, 64, 152)"></van-loading>
    <template v-if="list && list.length">
      <Item v-for="item in list" :key="item.id" :data="item">
        <template #button>
          <div class="item-button" @click="colseComment(item.id)">关闭</div>
        </template>
      </Item>
    </template>
    <template v-else>
      <div class="empty-text">您没有相关留言</div>
    </template>
  </van-tab>
</template>

<script>
import { Tab, Loading } from 'vant'
import { getCommentList } from '@/api/comments.js'
import messageHandler from '@/views/VisitorBook/mixins/messageHandler'
import { mapGetters } from 'vuex'

export default {
  name: 'InProcess',
  props: {
    display: String
  },
  mixins: [messageHandler],
  data () {
    return {
      loading: false,
      list: []
    }
  },
  computed: {
    ...mapGetters(['userData'])
  },
  watch: {
    display: {
      immediate: true,
      handler (value) {
        if (value === 'inProcess') {
          this.getCommentList()
        }
      }
    }
  },
  activated () {
    this.getCommentList()
  },
  components: {
    [Tab.name]: Tab,
    [Loading.name]: Loading,
    Item: () => import('./components/Item.vue')
  },
  methods: {
    /**
     * 获取留言列表
     */
    getCommentList () {
      // 定义用户的数据
      const params = {
        userId: this.userData.userId
      }
      this.loading = true
      getCommentList(params)
        .then(res => {
          this.list = res.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    /**
     * 关闭留言信息
     */
    onCommentClosed (id) {
      // 刷新列表
      // this.getCommentList()
      // 把数据直接从列表删除：节省一次接口请求
      const index = this.list.findIndex(item => item.id === id)
      if (index >= 0) {
        this.list.splice(index, 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.van-loading {
  position: absolute;
  z-index: 1;
  left: 50vw;
  top: 50vh;
  transform: translate(-50%, -50%);
}
</style>
