<template>
  <div class="visitor-book-list">
    <van-tabs class="tabs-panel" v-model="currentType" sticky lazy-render>
      <!-- 正在进行 -->
      <in-process :display="currentType" />
      <!-- 历史留言 -->
      <history :display="currentType" />
      <!-- 新增留言 -->
      <create :display="currentType" />
    </van-tabs>
  </div>
</template>

<script>
import { Tabs } from 'vant'
// 不同状态的留言
import InProcess from './InProcess.vue'
import History from './History.vue'
import Create from './Create.vue'

export default {
  name: 'VisitorBook',
  data () {
    return {}
  },
  computed: {
    currentType: {
      get () {
        const query = this.$route.query
        return query.t ? query.t : 'inProcess'
      },
      set (value) {
        this.$router.replace({
          name: 'VisitorBook',
          query: {
            t: value
          }
        })
      }
    }
  },
  watch: {},
  components: {
    [Tabs.name]: Tabs,
    InProcess,
    History,
    Create
  },
  activated () {
    this.$store.commit('updateLayout', {
      footerHeight: 'none'
    })
  },
  created () {
    this.$store.commit('updateLayout', {
      footerHeight: 'none'
    })
  }
}
</script>

<style lang="scss" scoped>
.visitor-book-list {
  padding-left: 10px;
  padding-right: 10px;
}
::v-deep .tabs-panel {
  .van-tabs__wrap {
    height: 100px;
  }
  .van-tabs__nav {
    background-color: #ededed;
    border-radius: 6px;
    padding-bottom: 0;
  }
  .van-tabs__line {
    bottom: 0;
    border-radius: 0;
    width: 4.5em;
    background-color: #00ae7e;
  }
  .van-tabs__content {
    margin-top: 20px;
  }
  .van-tab {
    font-size: 26px;
  }
  .van-tab--active {
    font-weight: bold;
    color: #00ae7e;
  }

  .van-sticky--fixed {
    top: $header-height - 0px;
    left: 10px;
    right: 10px;
    // .van-tabs__nav {
    //   background-color: #fff;
    // }
  }
}
</style>
