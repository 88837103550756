import { colseComment, removeComment } from '@/api/comments.js'
import { Notify } from 'vant'

export default {
  methods: {
    /**
     * 关闭留言
     */
    colseComment (id) {
      const targetId = id instanceof Event ? this.id : id
      colseComment({
        id: targetId,
        userId: this.$store.getters.userData.userId
      }).then(() => {
        Notify({
          type: 'success',
          message: '留言关闭成功'
        })
        // 下一步操作
        this.onCommentClosed && this.onCommentClosed(targetId)
      })
    },
    /**
     * 删除留言
     */
    deleteComment (id) {
      const targetId = id instanceof Event ? this.id : id
      removeComment({
        id: targetId,
        userId: this.$store.getters.userData.userId
      }).then(() => {
        Notify({
          type: 'success',
          message: '留言删除成功'
        })
        // 下一步操作
        this.onCommentRemoved && this.onCommentRemoved(targetId)
      })
    },
    /**
     * 自己的方法
     */
    onCommentClosed () {
      // 返回上一页
      const meta = this.$route.meta
      const prevPage = meta && meta.prevPage ? meta.prevPage : null
      if (prevPage) {
        this.$router.replace(prevPage)
      } else {
        this.$router.back()
      }
    },
    onCommentRemoved () {
      // 返回上一页
      const meta = this.$route.meta
      const prevPage = meta && meta.prevPage ? meta.prevPage : null
      if (prevPage) {
        this.$router.replace(prevPage)
      } else {
        this.$router.back()
      }
    }
  }
}
